#Cambiar-Contrasenia-Contenedor-Formulario{
    margin:0;
    padding: 0;
    display: flex;
    align-items: center;
    place-content: center;

    position: absolute;
    /* background-color: red; */
    width: 40%;
    height: 100vh;
}

#Cambiar-Contrasenia-Card-Formulario{
    width: 418px;
    /* height: 312px; */
    background: #FFFFFF;
    border-radius: 8px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
}

#Titulo-Input-Cambiar-Contrasenia-Formulario{
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #1876F2;
    padding-bottom: 3px;
}


#Cambiar-Contrasenia-Formulario-Input{
    height: 30px;
    background: #F0F2F5;
    border: 0;
    padding-left: 10px;
    font-family: Segoe UI;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
}

#Cambiar-Contrasenia-Formulario-Input:focus{
    border: none;
    outline: none;
}

#Btn-Guardar-Cambiar-Contrasenia-Formulario{
    background: #1876F2;
    border-radius: 8px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
    width: 90px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#Titulo-Cambiar-Contrasenia-Card-Formulario{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 33px;
    color: #1876F2;
    margin-bottom: 15px;
}