
.ant-drawer-content-wrapper{
    
}


.ant-drawer-content{
    background: transparent !important;
    width: 700px !important;
    overflow:hidden;
}

#Contenedor-Sidebar-Principal{
    position:relative;
    height: 100%;
    overflow:scroll
}

#Contenedor-Sidebar-Principal::-webkit-scrollbar {
    display: none;
}

#Cabecera-Sidebar-Principal{
    width: 300px;
    height: 70px;
    /* background: black; */
    margin-bottom: 40px;
}

#Cuerpo-Sidebar-Principal{
    width: 300px;
    /* background: yellow; */
    text-align: -webkit-center;
}

#Fila-Cuerpo-Sidebar-Principal{
    height: 60px;
    padding-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    /* position: relative; */
    width: 85%;
    display: flex;
    text-align-last: left;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
}

#Contenedor-Fila-Cuerpo-Sidebar-Principal:hover #SubMenu-Fila-Cuerpo-Sidebar-Principal{
    opacity: 1;
    width: 320px;
}

#Contenedor-Fila-Cuerpo-Sidebar-Principal:hover #Fila-Cuerpo-Sidebar-Principal{
    background: #F0F2F5;
    border-radius: 15px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09); */
}

/* ELIMINADO */

#Imagen-Fila-Cuerpo-Sidebar-Principal{
    width: 50px;
    margin-right: 10px;
    opacity: 1;
    position: absolute;

    background: #E4E6EB;
    border-radius: 100px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    padding: 0;
}

#Imagen-Selecccionado-Fila-Cuerpo-Sidebar-Principal{
    width: 50px;
    margin-right: 10px;
    opacity: 0;
    border: 2px solid white;
    border-radius: 100px;
    background: #E4E6EB;
}

#Texto-Fila-Cuerpo-Sidebar-Principal{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #323233;
}

#SubFila-Fila-Cuerpo-Sidebar-Principal{
    padding: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#SubFila-Fila-Cuerpo-Sidebar-Principal-Seleccionado{
    padding: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    background: #E7F3FF;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    border-radius: 6px;

    margin-bottom: 3px;
}

#SubTexto-Fila-Cuerpo-Sidebar-Principal-Seleccionado{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #1876F2;
}

#SubFila-Fila-Cuerpo-Sidebar-Principal:hover{

    /* background: #E7F3FF; */
    background: #F0F2F5;
    border-radius: 6px;
    padding: 20px;
    height: 30px;
    display: flex;
    align-items: center;
}

#SubFila-Fila-Cuerpo-Sidebar-Principal:hover #SubTexto-Fila-Cuerpo-Sidebar-Principal{
    /* font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px; */
    /* color: #1876F2; */
}

#SubFila-Fila-Cuerpo-Sidebar-Principal-Primero{
    padding: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    background: #F0F2F5;
    border-radius: 6px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#SubFila-Fila-Cuerpo-Sidebar-Principal-Primero:hover{

    /* background: #E7F3FF !important;
    border-radius: 6px;
    padding: 20px;
    height: 30px;
    display: flex;
    align-items: center; */

    background: #F0F2F5 !important;
    border-radius: 6px;
    padding: 20px;
    height: 30px;
    display: flex;
    align-items: center;
}

#SubFila-Fila-Cuerpo-Sidebar-Principal-Primero:hover #SubTexto-Fila-Cuerpo-Sidebar-Principal{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #323233;
}

#SubTexto-Fila-Cuerpo-Sidebar-Principal{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #323233;
}

#SubMenu-Fila-Cuerpo-Sidebar-Principal{
    position: absolute;    
    width: 0px;    
    /*height: 100px; */
    background: white;
    left: 300px;
    padding-top: 20px;
    padding-bottom: 20px;
    opacity: 0;
}

#SubMenu-Fila-Cuerpo-Sidebar-Principal:hover #SubFila-Fila-Cuerpo-Sidebar-Principal-Primero{
    padding: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    background: none;
}

#Contenedor-Fila-Cuerpo-Sidebar-Principal{
    height: 60px;
    align-items: center;
    width: 90%;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 3px;
}

#Icono-Cerrar-Menu-Slider:hover{
    background: #F0F2F5;
    border-radius: 50px;
    padding: 5px;
}


/*  */

.Imagen-Fila-Cuerpo-Sidebar-Principal{
    border-radius: 100px;
    border: 2px solid white;
    background-color: #E4E6EB;
}

#contenedor{
    margin: 0;
    padding: 0;
    overflow: scroll;
    height: 100%;
    width: 700px;
    /* background-color: blue; */
    position: relative;
}

#contenedor::-webkit-scrollbar {
    display: none;
}

nav{
    width: 300px;
    /* height: 100%; */
    position: absolute;
    top: 0;
    /* bottom: 0; */
    background: white;
    /* border-radius: 10px; */
    /* box-shadow: 5px 7px 10px rgba(0, 0, 0, .5); */
}

nav ul{
    position: relative;
    list-style-type: none;
    padding-left: 20px;
}

nav ul li{
    height: 60px;
    padding-left: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    margin-right: 30px;
    transition: .5s ease;
    /* background-color: red; */
    width: 100%;
}

nav ul .dropdown{
    height: 60px;
    padding-left: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    margin-right: 30px;
    transition: .5s ease;
    /* background-color: red; */
    width: 100%;
}

nav ul .dropdown-sinsubmenu:hover{
    background: #E7F3FF;
    /* margin-right: 50px; */
    width: auto;
}

nav ul .dropdown:hover{
    background: #E7F3FF;
    /* margin-right: 50px; */
    width: auto;
}

.dropdown-sinsubmenu:hover{
    filter: drop-shadow(-6px 6px 10px rgba(0, 0, 0, 0.25));
}
.dropdown_principal:hover{
    filter: drop-shadow(-6px 6px 10px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width:620px){
    .dropdown_principal{
        display: none;
    }
}
@media screen and (min-width:621px){
    .dropdown_principal_mobile{
        display: none;
    }
}

nav ul li:hover .Imagen-Fila-Cuerpo-Sidebar-Principal{
    border-radius: 100px;
    border: 2px solid white;
    background-color: #E4E6EB;
}

nav ul li a{
    display: flex;
    align-items: center;
    font-family: arial;
    font-size: 1.15em;
    text-decoration: none;
    text-transform: capitalize;
    /* padding: 10px 30px; */
    margin: 0;
    /* height: 50px; */
    
    /* border-radius: 30px; */
    /* place-content: center; */

    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}


nav ul li a:hover{
    /* background: rgba(0, 0, 0, 0.7); */
    /* background-color: aqua; */
    /* color: #fff; */
}

nav ul ul{
    position: absolute;
    left: 300px;
    width: 350px;
    top: 0;
    display: none;
    /* background: rgba(155, 39, 176, .4); */
    /* background-color: white; */
    padding-left: 50px;
    border-radius: 5px;
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 1); */
}

@media screen and (max-width:620px) {
    nav ul ul{
        left: 50px;
        top: 62px;
        z-index: 2;
    }
}

nav ul span{
    position: absolute;
    right: 20px;
    font-size: 1.5em ;
}

nav ul .dropdown {
    position: relative;
}

nav ul .dropdown:hover > ul{
    display: initial;
    background-color: white;
    padding-left: 30px;
}

@media screen and (max-width:620px) {
    nav ul .dropdown:hover > ul{
        padding-left: 0px;
    }
}

nav ul .dropdown > ul{
    margin-left: -50px;
    padding: 0;
}

nav ul .dropdown_two ul{
    position: absolute;
    left: 200px;
    top: 0;
}

nav ul .dropdown_two:hover ul{
    display: initial;
}

.dropdown{
    /* background-color: violet; */
}

nav ul ul li a{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #323233;
}

nav ul li ul{
    padding-top: 20px;
}

nav ul ul li{
    margin: 0;
}

nav ul ul li:hover{
    /* background: #F0F2F5; */
}


.SubMenu-Contenedor-Sidebar:hover{
    background: #F0F2F5;
    border-radius: 6px;
}

.IconoFlecha{
    position: absolute;
    left: 257px;
    top: 23px;
    z-index: 1;
}

.IconoFlechaDerecha{
    z-index: 1;
    left: 7px;
    position: absolute;
    top: 22px;
}

@media screen and (max-width:620px) {
    .IconoFlecha{
        transform: rotate(90deg);
        left: 250px;
    }
    .IconoFlechaDerecha{
        transform: rotate(90deg);
        display: none;
    }
    nav{
        width: 370px;
        height: auto;
    }
    #Cabecera-Sidebar-Principal{
        width: 335px;
    }
    nav ul .dropdown{
        height: auto;
    }
    nav ul ul{
        left: 0px;
        top: 0;
    }
    nav ul li ul {
        position: relative;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
    }
    nav ul li ul li{
        background: #a1a1b610;
        width: 95%;
        margin-bottom: 10px;
        height: 27px ;   
    }
    nav ul li ul li:hover{
        color: rgb(24, 118, 242);
        font-weight: bold;
        background: #E7F3FF;
    }
    nav ul li ul li:active{
        color: rgb(24, 118, 242);
        font-weight: bold;
        background: #E7F3FF;
    }
    nav ul li{
        width: 92%;
        flex-direction: column;
        justify-content: center;
    }

    .Lista-separador{
        width: 92%;
        height: 1px;
        background: #d4d4db;
        position: relative;
        top: 15px;
    }
    nav ul li ul .SubMenu-Contenedor-Sidebar:nth-last-child(1) li .Lista-separador{
        display: none;
    }
    /* .dropdown_principal_mobile:hover{
        padding: 4px;
        border-radius: 10px;
        background: #E7F3FF;
        margin-right: 15px;
    } */
    nav ul .dropdown:hover{
        background: white !important;
    }
    nav ul .dropdown ul{
        transition: all 5s ease-out;
    }
    nav ul .dropdown:hover ul{
        transform: translateY(100%);
    }
    nav ul .dropdown:hover > ul{
        display: initial;
    }
    

}
@media screen and (max-width:320px) {
    nav ul li{
        width: 83% !important;
    }
}
@media screen and (min-width:621px) {
    .Lista-separador{
        display: none;
    }
}

